// @flow
import React from 'react';

type formFieldProps = {|
  active?: ?boolean,
  checked: ?boolean,
  className: ?string,
  disabled?: boolean,
  error: ?any,
  input: Object,
  label?: string,
  max: ?any,
  min: ?any,
  maxLength?: ?string,
  name: ?string,
  onError?: ?Function,
  placeholder: ?string,
  touched?: ?boolean,
  type: ?string,
  onKeyPress?: ?Function,
|};

const FormField = (props: formFieldProps): React$Element<'div'> => {
  const {
    input,
    placeholder,
    className,
    type,
    checked,
    max,
    min,
    maxLength,
    touched,
    active,
    error,
    onError,
    disabled,
    onKeyPress,
    meta = {},
  } = props;

  const isChecked = type == 'checkbox' && !!checked ? checked : null;
  const maxVal = type == 'date' && max ? max : null;
  const minVal = type == 'date' && min ? min : null;

  const hasError = error || meta.error;
  const isTouched = touched || meta.touched;

  if (onError && (touched || active)) {
    onError(input.name, error, touched || active);
  }
  return (
    <div className="form-group">
      <input
        {...input}
        placeholder={placeholder}
        className={className}
        type={type}
        checked={isChecked}
        max={maxVal}
        min={minVal}
        maxLength={maxLength}
        disabled={disabled}
        onKeyPress={onKeyPress}
      />
      {isTouched && hasError && (
        <span className="validation-message">{hasError}</span>
      )}
    </div>
  );
};

FormField.defaultProps = {
  placeholder: '',
  className: '',
  name: '',
  error: '',
  checked: null,
  max: null,
  min: null,
  disabled: false,
};

export default FormField;
